@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,700;1,800&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
   background-color: #1c1c1c;
   font-family: 'Montserrat', sans-serif;
}
.container{
  margin: 18px 25px;
  padding-bottom: 3.8rem;
}
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  color: #ccc;
}
.mdes, .tldes{
  font-weight: lighter;
}
.middle{
  color: #ccc;
  text-align: center;
  margin: 20px 0px;
}
.mhead{
  font-size: 2rem;
}
.mdes{
  font-size: 14px;
  margin: 10px 0px;
}
.btop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.bleft{
  color: #ccc;
  font-weight: lighter;
}
.btn{
  border: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 1rem;
  background-image: linear-gradient(to right, rgb(53, 48, 204), rgb(188, 14, 173));
}
.cardsdiv{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  
}
.cards{
  margin: 15px 5px;
}
.carditem{
  border-radius: 15px;
  margin: 10px 0px;
}
.carddes{
  text-align: center;
  color: white;
  font-weight: bold;
  margin-top: 1rem;
}
.item{
  width: 100%;
  margin: 30px 20px;
}
.item h3{
  font-weight: lighter;
}
.carditem:hover{
   transform: scale(1.1);
   transition: all 0.3s;
}
.card1{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
input[type=checkbox]{
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 5px;
  /* more style */
}
input[type=checkbox]::after {
  content: '✓';
  font-size: 1.1rem;
  background: rgb(22 198 12);
  border: 1px solid black;
  color: white;
  display: flex ;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* // Responsive code */
@media only screen and (max-width: 600px){
.carditem{
    width: 7em;
    height: 10em;
}
.mhead{
  font-size: 2rem;
}
.mdes{
  font-size: 0.8rem;
}
.btn{
  padding: 12px 28px;
}
}
@media only screen and (min-width: 600px){
  .carditem{
      width: 8em;
      height: 12em;
  }
  .tlhead,.tldes, .bleft{
    font-size: 1.5rem;
  }
  .btn{
    padding: 14px 30px;
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 768px){
  .carditem{
      width: 8em;
      height: 14em;
  }
  .mhead{
    font-size: 2rem;
  }
  .mdes{
    font-size: 1rem;
  }
  .tlhead,.tldes{
    font-size: 2rem;
  }
  .btn{
    padding: 12px 35px;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1200px){
  .carditem{
      width: 12em;
      height: 20em;
  }
  .mhead{
    font-size: 3.8rem;
  }
  .bleft, .tldes,  .tlhead{
    font-size: 1.5rem;
  }
  .mdes{
    font-size: 2rem;
    font-weight: lighter;
  }
  .carddes{
   font-size: 1.3rem; 
  }
  .btn{
    padding: 12px 35px;
    font-size: 1.3rem;
  }
}
.mt{
  margin-top:10px;
  text-align: center;
  color:white;
}
.white{
  color:white;
  text-decoration: none;
}
